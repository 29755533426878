#main {
    margin-left: 300px;
    padding: 2rem;
    @media screen and (max-width: 1199px) {
        margin-left: 0;
    }
    &.layout-navbar {
        padding: 0;
    }
    &.layout-horizontal {
        padding: 0;
        margin: 0;
    }
    #main-content {
        padding: 2rem;
    }
}
.page-heading {
    margin:  0 0 2rem;
    h3 {
        font-weight: bold;
    }
}
.page-title-headings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .5rem;
    h3 {
        margin-bottom: 0;
        margin-right: 1rem;
    }
    .breadcrumb {
        margin-bottom: 0;
    }
}
a {
    text-decoration: none;
}
.bg-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}
.overlay-dark-blue::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #25396fa1;
    z-index: 9999;
}
.z-index-99999 {
    z-index: 99999;
}