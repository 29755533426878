div.dataTables_wrapper {
    div.dataTables_length {
        @media screen and (max-width: 767px) {
            text-align: left !important;
        }
    }
    div.dataTables_filter {
        @media screen and (max-width: 767px) {
            text-align: left !important;
        }
    }
    div.dataTables_info {
        @media screen and (max-width: 767px) {
            text-align: left !important;
        }
    }

    div.dataTables_paginate.paging_simple_numbers {
        ul.pagination {
            @media screen and (max-width: 767px) {
                justify-content: flex-start !important;
            }
        }
    }
}
